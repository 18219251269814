
import { Component, Vue, Getter } from "nuxt-property-decorator";
import {
  BIcon,
  BIconHouse,
  BIconWatch,
  BIconWallet2,
  BIconBuilding,
  BIconThreeDots,
  BIconFilter,
  BIconArrowRightCircle,
  BIconPen,
  BIconArrowLeftCircle,
  BIconArrowLeftShort,
  BIconBook,
  BIconList,
} from "bootstrap-vue";
import { Genre } from "~/types/Genre";

@Component({
  components: {
    BIcon,
    BIconHouse,
    BIconWatch,
    BIconWallet2,
    BIconBuilding,
    BIconThreeDots,
    BIconFilter,
    BIconArrowRightCircle,
    BIconPen,
    BIconArrowLeftCircle,
    BIconArrowLeftShort,
    BIconBook,
    BIconList,
  },
})
export default class TheSuggestionButton extends Vue {
  @Getter("preset/allGenres") allAvailableGenres!: Genre[];

  changeGenre(event: any) {
    this.$scrollOntop();
    this.$store.commit("typesense/clearResults");
    this.$store.commit("filter/resetFilterMutation", this.$store.getters["tenant/ivgId"]);
    this.$store.commit("filter/setGenreFilter", [event.value]);
    this.$store.commit("paginator/resetToPage");
    this.$router.push({ name: event.alias.toLowerCase() });
    if (this.$router.currentRoute.name === event.value.toLowerCase().replace("/", "")) {
      this.$store.dispatch("typesense/getData");
    }
  }
}
